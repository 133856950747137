import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './Home';
      

export default function App() {
  
  return (
       <BrowserRouter>
            <Switch>
              <Route path="/" component={() => (<Home />) } >
                <Home/>
              </Route>
            </Switch>
        </BrowserRouter>
  )
}
