import React, { Fragment, useState } from 'react'

import logo from './images/white-on-transparent.png';

import Carousel, { consts } from 'react-elastic-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import image5 from './images/training.png';
import image2 from './images/dreamstime_xs_111954140.jpg';
import image3 from './images/affordable-housing.png';
import image4 from './images/dreamstime_xs_85936032.jpg';
import image1 from './images/dreamstime_xs_121862608.jpg';
import image6 from './images/dreamstime_xs_146058335.jpg';

import './App.css';



const Home = () => {
    const main = React.createRef();
    
    const mainx = React.createRef();

    const mainy = React.createRef();
    
    const [show, setShow] = useState(false);
 

     const [ items ] = useState([
        { id: 1, title: 'International business syndication and synergies', image: image1},
        { id: 2, title: 'Funding of infrastructural projects and Export products manufacturing projects', image: image2},
        { id: 3, title: 'Affordable housing projects for Governments, parastatals and institutions', image: image3},
        { id: 4, title: 'Trade Finance and forfaiting', image: image4},
        { id: 5, title: 'Continuous professional development and capacity building', image: image5},
        { id: 6, title: 'Capital and Logistics support for the production and marketing of Energy and Energy products', image: image6}
     ]); 


    const [breakPoints] = useState([
        {
            width: 1,
            itemsToShow: 1
        },
        {
            width: 550, itemsToShow: 2,
            itemsToScroll: 1
        },
        {
            width: 750, itemsToShow: 3,
            itemsToScroll: 1
        }
      ])


    const myArrow = ({ type, onClick, isEdge }) => {
        const pointer = type === consts.PREV
            ? ( <ChevronLeftIcon className={!isEdge ? 'slider-icon' : 'slider-icon-disabled'} /> )
            : ( <ChevronRightIcon className={!isEdge ? 'slider-icon' : 'slider-icon-disabled'} /> )
        
        return (
            <button className='slider-button' onClick={onClick} disabled={isEdge}>
                {pointer}
            </button>
        )
    }

    const setServices = () => {
        setShow(!show);
    }


    const handleScroll = e => {
        e.preventDefault();
        const refmain = main.current;
        window.scrollTo({
            top: refmain.offsetTop,
            left: 0,
            behavior: "smooth"
        });
    };

    const handleContactScroll = e => {
        e.preventDefault();
        const refmainx = mainx.current;
        window.scrollTo({
            top: refmainx.offsetTop,
            left: 0,
            behavior: "smooth"
        });
    };

    const handleScrollUp = e => {
        e.preventDefault();
        const refmainy = mainy.current;
        window.scrollTo({
            top: refmainy.offsetTop,
            left: 0,
            behavior: "smooth"
        });
    };


   
    const renderCarousel = () => {
            return items.map((item, i) => ( <div className='slider-item' key={item.id}><div><img className='slider-image' src={item.image} alt='' />
                            {item.title}</div></div>))
        
        
    }
    
    return (
        <Fragment>
            {/* Navbar */}
            <header id='showcase' className='grid' ref={mainy} >
                <div className='nav-bg-img'></div>
                <div id='header-showcase' className='content-wrap'>
                    <img src={logo} alt='' />
                    <div>
                        <a href='/#' className='btn' onClick={handleContactScroll} >Contact Us</a>
                        <a href='/services' className='btn' onClick={handleScroll} >Services</a>
                        
                    </div>
                </div>
            </header>


            
            <main id='main'>

                {/* sec-3 */}
                <section id='sec-3' className='grid container'>
                    <div className='content-wrap'>
                        <h2 className='content-title' >About Our Company</h2>
                        <div className='content-text'> 
                            <p>COBB SYNERGIES CONSORTIUM is a highly motivated and highly experienced group
                                of experts in many fields of finance, industry and commerce. We are a multi-national team of
                                professionals well-seasoned and technically qualified working with forward looking banks and
                                financial institutions as well as other associates in the industry to create and implement
                                innovative and non-standard financial solutions. 

                                The team at Cobb Synergies Consortium is comprised of knowledge-based professionals with a range of backgrounds in investment
                                banking, corporate finance, experience in government, and in legal practice. We operate with
                                integrity and conform to international norms of best business practice and ethics and avoid
                                conflicts of interest. Each client can be assured of our utmost confidentiality and strict
                                adherence to the rules and regulations of our partner banks and lending institutions. 

                                Our business is to design a targeted pool of resources to provide clients with an unparalleled level
                                of funding and logistical support solutions to fit into their industrial or commercial needs;
                                either for expansion or for acquisition or to develop their projects. We concentrate on
                                emerging markets.
                            </p>
                        </div>
                    </div>
                </section>
                
                {/* sec-1  */}
                <section id='sec-1' className='grid container'>

                    <div className='content-wrap'>  
                        <div className='content-text .slider'>  
                            <br/>
                            <Carousel
                                autoPlaySpeed={4000}
                                enableAutoPlay={true}
                                renderPagination={() => <div />}
                                renderArrow={myArrow}
                                breakPoints={breakPoints}
                                focusOnSelect={false} >
                                    {renderCarousel()}
                            </Carousel>
                            
                           
                        </div>
                    </div>

                </section>
                
                { /* sec-2*/}
                <section id='sec-2' className='grid container wave'>
                    <div className='content-wrap' onClick={setServices} >
  {/* className='wave'<div className='wavy'><svg viewBox="0 -20 700 110" width="100%" height="100%" preserveAspectRatio="none">
    <path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#CEB964" />
    <path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#00273F" />
    </svg></div>*/}
                            <h2 ref={main}>Our Services</h2>
                    <div className=''> 

                            <p>Whether you are a company successfully working your oil fields or you are in power generation, 
                            you will always need additional machinery or engineering materials, funding and logistics to 
                            increase your capacity.</p>

                            <p>If you are a government entity, or a project contractor for major infrastructural projects you will 
                            need funding to deliver a successful project. If you want to 
                            acquire or build a production plant or increase the output capacity of your existing plant, you 
                            will need capital or credit line or a working capital with a line of credit. </p>

                            <p>Governments, parastatals or institutions often want affordable housing for their working men and women. 
                            Our unique funding solutions are designed to remove or lessen the financial burden on the proponents 
                            of the whole project from start to finish. </p>

                            <p>If you are in commercial trade and in need of support 
                            to boost your business and have nowhere to turn because your own bank cannot support you, turn to us. </p>

                            <p>Cobb Synergies Consortium is well equipped with a comprehensive set of financing solutions and we are 
                            specialized in deploying our expertise to meet your business needs. As an international business, you 
                            might want to partner with other businesses to deliver a particular project. We specialise in business 
                            syndication between business owners seeking mutual benefit. </p>

                            <p>Times are changing very fast due to fast 
                            evolving technology and business methodologies. If you are a professional person, don’t get stuck in the past. 
                            Participate in our continuous professional development and capacity building program to stay ahead in your field. </p>
                            
                            <p>In all of our services involving funding, we work with our banks, financial institutions 
                            and investors to provide our clients with a very attractive non-traditional approach to funding. 
                            An IPP, PPP or a project financing of any kind usually demands for some form of sovereign or 
                            government support, a traditional bank guarantee or letter of support from a client’s bank as 
                            pre-condition for obtaining project funding or investment.  </p>

                            <p>Unlike these methods that choke the client and make it impossible or extremely unlikely for the client to obtain finance, we have a 
                            modern and innovative method that can be properly structured to lessen the insufferable debt burden 
                            on the client. Even for governments, our innovative method for infrastructural finance and affordable 
                            housing is designed to remove the usual liability on their balance sheet.</p>

                            <p>Government will also find that our method can help redeem their concerns under obligations to the World Bank or IMF.
                            Our associates have used this tried and tested innovative method of international project finance an provided facilities for clients in Germany, United Kingdom, USA, China, Malaysia, Singapore, Korea, India, Pakistan, Georgia, Russia, South Africa,
                            Eastern Europe, Namibia, Tanzanisa, Zambia, Zimbabwe, Ghana, Gambia, Cote d'Ivoire, Egypt., Jamaica, etc.</p>

                            <p>If you need our services please send us a one page profile of your project plus requirement.</p>
                        </div>
                    </div>
                </section>
                
                </main>

            <footer id='main-footer' className='grid' ref={mainx} >
                <div className='footer-wide'>
                    <div id='margin-h1'>
                        <h1>CobbSynergies</h1>
                    </div>
               
                    <div id='margin-p' className='d-f'>
                        <div>
                            <p  className=''>
                                Tel: +442071831162 <br/>
                                Fax: +442071831046 <br/>
                                Mobile: +447308439096 <br/>
                                info@cobbsynergies.com
                            </p>
                        </div>
                        <div onClick={handleScrollUp} className='btn-small'>
                            <ArrowUpwardIcon   className=''/>
                            
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    )
}
export default Home;
